<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" flat>
      <v-toolbar-title>Checklist Manager</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="color3Text color3--text" fab small @click.stop="newChecklist">
        <v-icon small>fas fa-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="checklists"
      :options.sync="pagination"
      item-key="id"
      :loading="loading"
      :search="search"
      :show-select="doDelete"
      v-model="selected"
    >
      <template v-slot:progress>
        <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div class="subheading">{{item.name}}</div>
        {{item.note}}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="color3 color3Text--text"
          small fab
          @click="select(item)"
        >
          <v-icon small>fas fa-pencil</v-icon>
        </v-btn>
        <v-btn
          color="color3Text color3--text"
          small fab
          :to="{ name: 'tournament-checklist', params: { tournamentId: tournament.id, checklistId: item.id }}"
        >
          <v-icon small>fas fa-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-container class="pa-0">
      <v-expand-transition>
        <div v-if="selected.length">
          <v-row dense class="px-4">
            <v-col cols="12" class="subheading">
              What would you like to do with the selected checklists?
            </v-col>
            <v-col cols="12">
              <v-btn
                color="color3 color3Text--text"
                class="mr-2"
                @click.stop="disableSelected"
              >Disable</v-btn>
              <v-btn
                color="error white--text"
                @click.stop="deleteSelected"
              >Delete</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
      <v-row dense justify="end">
        <v-btn color="color3" text class="xsmall" icon @click.stop="asyncData" :loading="loading">
          <v-icon>fas fa-redo-alt</v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <!-- DIALOG -->
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
      :persistent="persistDialog"
    >
      <checklist-details
        ref="details"
        :checklist="selectedChecklist"
        :active="dialog"
        @cancel-click="dialog = false"
        @editing-change="onEditingChange"
        :existing="checklists"
        @save-complete="onSaveComplete"
      ></checklist-details>
    </v-dialog>
    <!-- Confirm-->
    <vbl-confirm-dialog
      :ask="confirmDialog"
      @confirm="() => { if(confirmAction) confirmAction() }"
      @deny="confirmDialog = false"
      :loading="loading"
    ></vbl-confirm-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ChecklistDetails from './Details'
import Checklist from '@/classes/Checklist'

export default {
  props: ['active'],
  data () {
    return {
      checklists: [],
      loading: false,
      search: null,
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: '', value: 'actions', align: 'end', sortable: false }
      ],
      dialog: false,
      persistDialog: false,
      selectedChecklist: null,
      pagination: { itemsPerPage: 10 },
      selected: [],
      doDelete: true,
      confirmDialog: false,
      confirmAction: null
    }
  },
  computed: {
    ...mapGetters(['user', 'tournament'])
  },
  methods: {
    select (checklist) {
      this.selectedChecklist = new Checklist(checklist)
      this.dialog = true
    },
    asyncData () {
      this.loading = true
      this.$VBL.tournament.checklists.getAll(this.tournament.id)
        .then(r => { this.checklists = r.data.map(m => new Checklist(m)) })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    onEditingChange (val) {
      this.persistDialog = val
    },
    newChecklist () {
      this.select(new Checklist({ tournamentId: this.tournament.id }))
    },
    onSaveComplete (dto) {
      var e = this.checklists.find(f => f.id === dto.id)
      if (e) {
        e.update(dto)
      } else {
        this.checklists.push(dto)
      }
      this.dialog = false
    },
    deleteSelected () {
      this.confirmAction = this.performDelete
      this.confirmDialog = true
    },
    disableSelected () {
      this.confirmAction = this.performDisable
      this.confirmDialog = true
    },
    performDisable () {
      this.loading = true
      const ids = this.selected.map(m => m.id)
      this.$VBL.cart.checklists.disable(this.username, ids)
        .then(r => {
          this.selected.forEach(f => {
            f.disabled = true
          })
          this.selected = []
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.confirmAction = null
          this.confirmDialog = false
        })
    },
    performDelete () {
      this.loading = true
      const ids = this.selected.map(m => m.id)
      this.$VBL.tournament.checklists.delete(this.username, ids)
        .then(r => {
          this.checklists = this.checklists.filter(f => !ids.includes(f.id))
          this.selected = []
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
          this.confirmAction = null
          this.confirmDialog = false
        })
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.asyncData()
      }
    }
  },
  components: {
    ChecklistDetails
  },
  mounted () {
    this.asyncData()
  }
}
</script>

<style>

</style>
