<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>Checklist Details</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="color1 color1Text--text"
        fab
        small
        @click.stop="cancel(true)"
      >
        <v-icon small>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="myChecklist">
      <v-container class="pt-0">
        <v-row dense>
          <v-col cols="12" class="caption" v-if="!isNew">
            Id: {{myChecklist.id}} | Created: {{myChecklist.dtCreated ? myChecklist.dtCreated.format('LLLL') : 'NA'}} By: {{myChecklist.createdBy}}
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Name"
              v-model="myChecklist.name"
              :disabled="!editing"
              :error-messages="nameErrors"
              @blur="$v.myChecklist.name.$touch()"
              persistent-hint
              color="color3"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              label="Notes"
              v-model="myChecklist.note"
              :disabled="!editing"
              persistent-hint
              color="color3"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-checkbox
              label="Ask who"
              v-model="myChecklist.askWho"
              hide-details
              :disabled="!editing"
              color="color3"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-expand-transition>
          <div v-if="error">
            <v-alert type="error" :value="true">
              Oops: {{error}}
            </v-alert>
          </div>
        </v-expand-transition>
      </v-container>
    </v-card-text>
    <!-- BUTTONS -->
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        v-if="!myChecklist.isAdvanced"
        color="color3"
        text class="xsmall"
        @click.stop="advanced = !advanced"
      >
        {{advanced ? 'simple' : 'advanced'}}
      </v-btn>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <span>Save</span>
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn
              v-on="on"
              v-if="editing"
              color="success white--text"
              key="save"
              fab
              small
              @click.stop="onSaveClick"
              :loading="saving"
              :disabled="!valid || !dirty"
              class="mr-1"
            >
              <v-icon small>fas fa-save</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </v-tooltip>
      <v-tooltip top>
        <span>{{activeFab.tooltip}}</span>
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn
              v-on="on"
              :color="activeFab.color"
              :key="activeFab.icon"
              v-model="fab"
              fab
              small
              @click.stop="activeFab.onClick"
            >
              <v-icon small>{{activeFab.icon}}</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import Checklist from '@/classes/Checklist'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  props: ['checklist', 'active', 'existing'],
  data () {
    return {
      editing: false,
      iChecklist: {},
      fab: null,
      saving: false,
      advanced: false,
      error: null
    }
  },
  validations: {
    myChecklist: {
      name: { required }
    }
  },
  computed: {
    ...mapGetters(['user']),
    nameErrors () {
      const errors = []
      if (!this.$v.myChecklist.name.$dirty) return errors
      !this.$v.myChecklist.name.required && errors.push('A name is required')
      return errors
    },
    isNew () {
      return this.checklist && !this.checklist.id
    },
    myChecklist () {
      return this.editing ? this.iChecklist : this.checklist
    },
    activeFab () {
      return !this.editing ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        onClick: this.edit,
        tooltip: 'Edit'
      } : {
        color: 'red white--text',
        icon: 'fas fa-times',
        onClick: this.cancel,
        tooltip: 'Cancel'
      }
    },
    dirty () {
      return JSON.stringify(this.checklist) !== JSON.stringify(this.iChecklist)
    },
    valid () {
      return this.myChecklist.name
    },
    dto () {
      return this.myChecklist.dto
    }
  },
  methods: {
    onSaveClick () {
      this.$v.$touch()
      if (!this.valid) return
      this.saving = true
      console.log(this.dto)
      this.$VBL.tournament.checklists.post(this.dto)
        .then(r => this.$emit('save-complete', this.myChecklist))
        .catch(e => {
          console.log(e.response)
          this.error = e.response.data
        })
        .finally(() => { this.saving = false })
    },
    edit () {
      this.iChecklist = new Checklist(this.checklist)
      this.editing = true
    },
    cancel (close) {
      this.editing = false
      this.advanced = false
      this.iChecklist = {}
      this.$v.$reset()
      if (this.isNew || close === true) this.$emit('cancel-click')
    }
  },
  watch: {
    active: function (val) {
      if (!val) {
        this.cancel()
      } else {
        this.isNew && this.edit()
      }
    },
    editing: function (val) {
      this.$emit('editing-change', val)
    },
    myChecklist: {
      handler: function (val) {
        this.error = null
      },
      deep: true
    }
  },
  mounted () {
    this.isNew && this.edit()
  }
}
</script>

<style>

</style>
